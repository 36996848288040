import { Button, Richtext } from "@/atoms";
import { Container, Row } from "design-react-kit";

const TEXT_ALIGN = {
  LEFT: "left",
  CENTER: "center",
  RIGHT: "right",
};

const SmallParagraph = ({
  items,
  containerStyle = {},
  titleStyle = {},
  textStyle = {},
  ...props
}) => {
  const { title, text, cta, alignContent, hasCta } = items;

  const getTextAlign = (textAlign) => {
    switch (textAlign) {
      case TEXT_ALIGN.CENTER:
        return "text-center";
      case TEXT_ALIGN.RIGHT:
        return "text-right";
      case TEXT_ALIGN.LEFT:
        return "text-left";
      default:
        return "text-center";
    }
  };

  return (
    <section
      className={"small-paragraph_section section"}
      style={containerStyle}
    >
      <Container fluid className="small-paragraph_section_container">
        <Row
          className={`small-paragraph_text-wrapper justify-content-${alignContent} ${getTextAlign(
            alignContent
          )} `}
        >
          <h2
            className={`small-paragraph_title ${getTextAlign(alignContent)}`}
            style={titleStyle}
          >
            {title}
          </h2>
          <Richtext textAlign={alignContent} textStyle={textStyle}>
            {text}
          </Richtext>
          {hasCta ? (
            <Button
              href={cta?.url}
              internalUrl={cta?.internal_url}
              isInternalPage={cta?.internal_page}
              openInNewTab={cta?.open_link_in_new_tab}
              className={"small-paragraph_button btn-primary"}
            >
              {cta.label}
            </Button>
          ) : null}
        </Row>
      </Container>
    </section>
  );
};

export default SmallParagraph;
