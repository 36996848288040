const Turism = ({ className, style }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className={className}
    style={style}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.5 10.5C5.77614 10.5 6 10.7239 6 11V17C6 17.2761 5.77614 17.5 5.5 17.5C5.22386 17.5 5 17.2761 5 17V11C5 10.7239 5.22386 10.5 5.5 10.5Z"
      fill="#06C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.5 10.5C8.77614 10.5 9 10.7239 9 11V17C9 17.2761 8.77614 17.5 8.5 17.5C8.22386 17.5 8 17.2761 8 17V11C8 10.7239 8.22386 10.5 8.5 10.5Z"
      fill="#06C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.5 9C0.5 7.61929 1.61929 6.5 3 6.5H11C12.3807 6.5 13.5 7.61929 13.5 9V19C13.5 20.3807 12.3807 21.5 11 21.5H3C1.61929 21.5 0.5 20.3807 0.5 19V9ZM3 7.5C2.17157 7.5 1.5 8.17157 1.5 9V19C1.5 19.8284 2.17157 20.5 3 20.5H11C11.8284 20.5 12.5 19.8284 12.5 19V9C12.5 8.17157 11.8284 7.5 11 7.5H3Z"
      fill="#06C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 1.5C5.17157 1.5 4.5 2.17157 4.5 3V7C4.5 7.27614 4.27614 7.5 4 7.5C3.72386 7.5 3.5 7.27614 3.5 7V3C3.5 1.61929 4.61929 0.5 6 0.5H8C9.38071 0.5 10.5 1.61929 10.5 3V7C10.5 7.27614 10.2761 7.5 10 7.5C9.72386 7.5 9.5 7.27614 9.5 7V3C9.5 2.17157 8.82843 1.5 8 1.5H6Z"
      fill="#06C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3 20.5C3.27614 20.5 3.5 20.7239 3.5 21V22.5C3.5 22.7761 3.27614 23 3 23C2.72386 23 2.5 22.7761 2.5 22.5V21C2.5 20.7239 2.72386 20.5 3 20.5Z"
      fill="#06C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11 20.5C11.2761 20.5 11.5 20.7239 11.5 21V22.5C11.5 22.7761 11.2761 23 11 23C10.7239 23 10.5 22.7761 10.5 22.5V21C10.5 20.7239 10.7239 20.5 11 20.5Z"
      fill="#06C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.5 16C12.5 14.6193 13.6193 13.5 15 13.5H21C22.3807 13.5 23.5 14.6193 23.5 16V20C23.5 21.3807 22.3807 22.5 21 22.5H15C13.6193 22.5 12.5 21.3807 12.5 20V16ZM15 14.5C14.1716 14.5 13.5 15.1716 13.5 16V20C13.5 20.8284 14.1716 21.5 15 21.5H21C21.8284 21.5 22.5 20.8284 22.5 20V16C22.5 15.1716 21.8284 14.5 21 14.5H15Z"
      fill="#06C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13 15.5C13.2761 15.5 13.5 15.7239 13.5 16C13.5 16.8284 14.1716 17.5 15 17.5H21C21.8284 17.5 22.5 16.8284 22.5 16C22.5 15.7239 22.7239 15.5 23 15.5C23.2761 15.5 23.5 15.7239 23.5 16C23.5 17.3807 22.3807 18.5 21 18.5H15C13.6193 18.5 12.5 17.3807 12.5 16C12.5 15.7239 12.7239 15.5 13 15.5Z"
      fill="#06C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18 16.5C18.2761 16.5 18.5 16.7239 18.5 17V19C18.5 19.2761 18.2761 19.5 18 19.5C17.7239 19.5 17.5 19.2761 17.5 19V17C17.5 16.7239 17.7239 16.5 18 16.5Z"
      fill="#06C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18 11.5C17.1716 11.5 16.5 12.1716 16.5 13V14C16.5 14.2761 16.2761 14.5 16 14.5C15.7239 14.5 15.5 14.2761 15.5 14V13C15.5 11.6193 16.6193 10.5 18 10.5C19.3807 10.5 20.5 11.6193 20.5 13V14C20.5 14.2761 20.2761 14.5 20 14.5C19.7239 14.5 19.5 14.2761 19.5 14V13C19.5 12.1716 18.8284 11.5 18 11.5Z"
      fill="#06C"
    />
  </svg>
);

export default Turism;
