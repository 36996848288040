import Computer from "./Computer";
import World from "./World";
import Turism from "./Turism";
import Children from "./Children";

export default {
  computer: Computer,
  world: World,
  turism: Turism,
  children: Children,
};
