import { Card, CardBody, CardTitle, CardReadMore } from "design-react-kit";

const mainClassName = "investment-card";

const InvestmentCard = ({ header, title, cta }) => (
  <Card noWrapper className={`${mainClassName}_container`}>
    <div className={`${mainClassName}_container_header`}>
      <span>{header}</span>
      <CardTitle className={`${mainClassName}_container_header_title`} tag="h4">
        {title}
      </CardTitle>
    </div>
    <CardBody className={`${mainClassName}_container_body`}>
      <CardReadMore
        className={`${mainClassName}_container_body_cta`}
        iconName="it-arrow-right"
        text={cta.label}
        href={cta.url}
        target={cta.open_link_in_new_tab ? "_blank" : "_self"}
      />
    </CardBody>
  </Card>
);

export default InvestmentCard;
