const World = ({ className, style }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className={className}
    style={style}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 2.5C6.75329 2.5 2.5 6.75329 2.5 12C2.5 17.2467 6.75329 21.5 12 21.5C17.2467 21.5 21.5 17.2467 21.5 12C21.5 6.75329 17.2467 2.5 12 2.5ZM1.5 12C1.5 6.20101 6.20101 1.5 12 1.5C17.799 1.5 22.5 6.20101 22.5 12C22.5 17.799 17.799 22.5 12 22.5C6.20101 22.5 1.5 17.799 1.5 12Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.1294 10.8063C2.68566 11.2259 2.5 11.6295 2.5 12C2.5 12.3705 2.68566 12.7741 3.1294 13.1937C3.5742 13.6144 4.24467 14.0162 5.11463 14.3642C6.85177 15.059 9.2859 15.5 12 15.5C14.7141 15.5 17.1482 15.059 18.8854 14.3642C19.7553 14.0162 20.4258 13.6144 20.8706 13.1937C21.3143 12.7741 21.5 12.3705 21.5 12C21.5 11.6295 21.3143 11.2259 20.8706 10.8063C20.4258 10.3856 19.7553 9.98379 18.8854 9.63581C17.1482 8.94095 14.7141 8.5 12 8.5C9.2859 8.5 6.85177 8.94095 5.11463 9.63581C4.24467 9.98379 3.5742 10.3856 3.1294 10.8063ZM4.74324 8.70733C6.62538 7.95448 9.19125 7.5 12 7.5C14.8087 7.5 17.3746 7.95448 19.2568 8.70733C20.1964 9.08321 20.9904 9.54328 21.5577 10.0797C22.126 10.6172 22.5 11.2659 22.5 12C22.5 12.7341 22.126 13.3828 21.5577 13.9203C20.9904 14.4567 20.1964 14.9168 19.2568 15.2927C17.3746 16.0455 14.8087 16.5 12 16.5C9.19125 16.5 6.62538 16.0455 4.74324 15.2927C3.80355 14.9168 3.00955 14.4567 2.4423 13.9203C1.87398 13.3828 1.5 12.7341 1.5 12C1.5 11.2659 1.87398 10.6172 2.4423 10.0797C3.00955 9.54328 3.80355 9.08321 4.74324 8.70733Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.8063 20.8706C11.2259 21.3143 11.6295 21.5 12 21.5C12.3705 21.5 12.7741 21.3143 13.1937 20.8706C13.6144 20.4258 14.0162 19.7553 14.3642 18.8854C15.059 17.1482 15.5 14.7141 15.5 12C15.5 9.2859 15.059 6.85177 14.3642 5.11463C14.0162 4.24467 13.6144 3.5742 13.1937 3.1294C12.7741 2.68566 12.3705 2.5 12 2.5C11.6295 2.5 11.2259 2.68566 10.8063 3.1294C10.3856 3.5742 9.98379 4.24467 9.63581 5.11463C8.94095 6.85177 8.5 9.2859 8.5 12C8.5 14.7141 8.94095 17.1482 9.63581 18.8854C9.9838 19.7553 10.3856 20.4258 10.8063 20.8706ZM8.70733 19.2568C7.95448 17.3746 7.5 14.8088 7.5 12C7.5 9.19125 7.95448 6.62538 8.70733 4.74324C9.08321 3.80355 9.54328 3.00955 10.0797 2.44229C10.6172 1.87398 11.2659 1.5 12 1.5C12.7341 1.5 13.3828 1.87398 13.9203 2.44229C14.4567 3.00955 14.9168 3.80355 15.2927 4.74324C16.0455 6.62538 16.5 9.19125 16.5 12C16.5 14.8088 16.0455 17.3746 15.2927 19.2568C14.9168 20.1964 14.4567 20.9904 13.9203 21.5577C13.3828 22.126 12.7341 22.5 12 22.5C11.2659 22.5 10.6172 22.126 10.0797 21.5577C9.54328 20.9904 9.08321 20.1964 8.70733 19.2568Z"
      fill="black"
    />
  </svg>
);

export default World;
