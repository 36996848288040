import Richtext from "@/atoms/Richtext";
import InvestmentCard from "@/atoms/Cards/InvestmentCard";

import Icons from "@/molecules/Svgs/SvgsList";

const mainClassName = "highlighted-card-list";

const HighlightedCardList = ({ data, darkBackground }) => {
  const { title, description, section_title: sectionTitle, items, icon } = data;

  const Icon = Icons[icon] ? Icons[icon] : null;

  return (
    <section
      className={`${mainClassName + "_section"} ${
        darkBackground ? "darkBg" : ""
      }`}
    >
      <div className={`${mainClassName + "_section_container"}`}>
        <div className={`${mainClassName + "_section_container_paragraph"}`}>
          <h2
            className={`${
              mainClassName + "_section_container_paragraph_title"
            }`}
          >
            {title}
          </h2>
        </div>
        <div className={`${mainClassName + "_section_container_cards"}`}>
          <div
            className={`${mainClassName + "_section_container_cards_header"}`}
          >
            <div
              className={`${
                mainClassName + "_section_container_cards_header_title"
              }`}
            >
              {Icon && <Icon />}
              <h3>{sectionTitle}</h3>
            </div>
            <Richtext
              isDefault={false}
              className={`${
                mainClassName + "_section_container_cards_header_abstract"
              }`}
              textAlign={"left"}
            >
              {description}
            </Richtext>
          </div>
          <div className={`${mainClassName + "_section_container_cards_main"}`}>
            {items &&
              !!items.length &&
              items.map((item, index) => {
                console.log("item", item);
                return <InvestmentCard key={index} {...item} />;
              })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HighlightedCardList;
