const mainClassName = "progress-cards-list";

import ProgressCard from "@/atoms/Cards/ProgressCard";

const ProgressCardsList = ({ data, darkBackground }) => {
  const { title, items } = data;
  return (
    <section
      className={`${mainClassName + "_section"} ${
        darkBackground ? "darkBg" : ""
      }`}
    >
      <div className={`${mainClassName + "_section_container"}`}>
        <div className={`${mainClassName + "_section_container_paragraph"}`}>
          <h2
            className={`${
              mainClassName + "_section_container_paragraph_title"
            }`}
          >
            {title}
          </h2>
        </div>
        <div className={`${mainClassName + "_section_container_cards"}`}>
          <div className={`${mainClassName + "_section_container_cards_main"}`}>
            {items &&
              !!items.length &&
              items.map((item, index) => {
                console.log("item", item);
                return <ProgressCard key={index} {...item} />;
              })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProgressCardsList;
